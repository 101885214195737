import React, { useState, useEffect, useContext, useRef } from "react";
import sendbutton from "../../../assets/chatbot/paper-plane.png";
import axiosClient from "config/apiClient";
import "../animation.css";

type TypewriterProps = {
  text: string;
  delay: number;
};

const OpenContext = React.createContext(null);

const Typewriter: React.FC<TypewriterProps> = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [setIsSending] = useContext(OpenContext);

  useEffect(() => {
    let timeout: number;

    if (currentIndex < text.length) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
    } else {
      setIsSending(false);
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, text, setIsSending]);

  useEffect(() => {
    const chatbox = document.getElementById("chatbot-messages");
    if (chatbox) {
      chatbox.scrollTop = chatbox.scrollHeight;
    }
  }, [currentText]);

  return <span>{currentText}</span>;
};

const Chatbot = () => {
  const [data, setMessages] = useState([
    { content: "Hello! How can I assist you today?", role: "assistant" },
  ]);
  const [userMessage, setUserMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const messagesRef = useRef(null);

  useEffect(() => {
    const scrollChatToBottom = () => {
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    };
    scrollChatToBottom();
  }, [data]);

  const chatbotInstruction = async () => {
    const resp = await axiosClient.post(`chatbot/get/instruction`, {
      pathname: window.location.pathname,
    });
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        content: resp.data.instruction,
        role: "system",
      },
    ]);
  };

  useEffect(() => {
    chatbotInstruction();
  }, []);

  const handleSendMessage = () => {
    if (userMessage.trim() === "" || isSending) {
      return;
    }
    setIsSending(true);
    ResponseGenerator();
  };

  const ResponseGenerator = () => {
    const newUserMessage = { content: userMessage, role: "user" };
    setMessages((data) => [...data, newUserMessage]);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        content: "Generating Response",
        role: "animations",
      },
    ]);
    setTimeout(async () => {
      try {
        data.push({ content: userMessage, role: "user" });
        const chatCompletion = await axiosClient.post(`chatbot/get/response`, {
          prompt: data,
        });
        const chatbotResponse = chatCompletion.data?.response;
        if (chatbotResponse) {
          const newChatbotMessage = {
            content: chatbotResponse,
            role: "assistant",
          };
          setMessages((prevMessages) => [...prevMessages, newChatbotMessage]);
        } else {
          throw new Error("Empty response from the chatbot.");
        }
      } catch (error) {
        console.error("Error generating chat:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            content: "Error generating response. Please try again later.",
            role: "assistant",
          },
        ]);
      } finally {
        setMessages((prevMessages) =>
          prevMessages.filter((message) => !(message.role === "animations"))
        );
        setIsSending(false);
      }
    }, 10);

    setUserMessage("");
  };

  return (
    <div className="fixed right-1 top-[6%] z-50 ml-[2%] h-full max-w-[40vh]">
      <div className="m-0 mt-[10%] max-h-[85%] w-[37vh]  min-w-[25vh] max-w-[40vh] rounded-[5px] bg-[#d8dbdd] shadow-[1px_2px_3px_4px_rgba(20,20,20,0.4)]">
        <div className="flex flex-row justify-between rounded-t-[5px] bg-[#052d59] p-[15px] text-center text-white shadow-[1px_2px_3px_4px_rgba(20,20,20,0.4)]">
          <span className="p-[none] text-[1.3rem]">PubPundit</span>
        </div>
        <div
          className="h-[65vh] max-h-[66vh] min-h-[38vh] overflow-y-auto scroll-auto whitespace-pre-line break-normal bg-[#d8dbdd] p-[15px] text-left shadow-[1px_2px_3px_4px_rgba(20,20,20,0.4)]"
          id="chatbot-messages"
          ref={messagesRef}
        >
          {data.map((message, index) => (
            <div
              id="message"
              key={index}
              className={
                message.role === "user"
                  ? "mx-0 my-[2%] ml-auto w-fit max-w-full overflow-y-auto break-words rounded-[10px] rounded-tr-[1px] bg-[#0079fe] p-[3%] text-left text-white transition-[background-color] duration-[0.3s] ease-[ease]"
                  : message.role === "assistant"
                  ? "mx-0 my-[2%] max-w-full overflow-y-auto break-words rounded-[10px] rounded-tl-[1px] bg-[#f4f5f6] p-[3%] text-[#000000] opacity-80 transition-[background-color] duration-[0.3s] ease-[ease]"
                  : message.role === "animations"
                  ? "mx-0 my-[2%] flex h-9 w-fit flex-row items-center justify-center gap-2 overflow-y-auto  break-words rounded-[10px] rounded-tl-[1px] bg-[#f4f5f6] p-[3%] text-center text-[#000000] opacity-80 transition-[background-color] duration-[0.3s] ease-[ease]"
                  : null
              }
            >
              {message.role === "system" ? null : message.role ===
                "assistant" ? (
                <OpenContext.Provider value={[setIsSending]}>
                  <Typewriter text={message.content} delay={15} />
                </OpenContext.Provider>
              ) : message.role === "animations" ? (
                <>
                  {/* <div className="h-5 w-5 animate-spin rounded-full border-b-2 border-indigo-700"></div> */}
                  <div className="bouncing-ball1"></div>
                  <div className="bouncing-ball2"></div>
                  <div className="bouncing-ball3"></div>
                </>
              ) : (
                `${message.content}`
              )}
            </div>
          ))}
        </div>
        <div className="flex h-[10vh] max-h-[10vh] min-h-[8vh] justify-start rounded-b-md border-t border-solid border-t-[#ccc] bg-[#d8dbdd] p-[3%] text-[#000000] shadow-[1px_5px_3px_4px_rgba(20,20,20,0.4)]">
          <textarea
            id="user-message"
            className="max-w-[35vh] grow resize-none overflow-y-auto rounded-[2px_2px_2px_2px] border-[none] p-[1.5vh] pt-[0.5vh] transition-[background-color] duration-[0.3s] ease-[ease]"
            placeholder="Ask a question..."
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            disabled={isSending}
          ></textarea>
          <button
            className="bg-transparent relative top-[25%] ml-[1%] max-h-[4vh] min-h-[2vh] w-[4vh] min-w-[2vh] max-w-[10vh] cursor-pointer border-[none] bg-center bg-no-repeat text-[1] text-white hover:scale-110"
            id="send-button"
            name="send-button"
            onClick={handleSendMessage}
            disabled={isSending}
          >
            <img src={sendbutton} alt="Send"></img>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
